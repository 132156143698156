











































































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import AvailableSubject from '~/components/course/available-subject'
import {CourseEditStatus, Instructor} from '~/components/data-class/data-class'
import {toInstanceForce} from '~/utils/Serializer'
import {CoursePermission} from '~/utils/permissions'
import {userPropicURL} from '~/utils/misc'

@Component({
      components: {},
    metaInfo() {
        return {
            title: 'Course Edit Review'
        }
    }
  })

  export default class CourseList extends Vue {
      static permission = CoursePermission.ViewCourse

      courseSubject = AvailableSubject

      selectedCourseSubject = ''

      instructors: Instructor[] = []

      selectedInstructor = ''

      tableData: Array<{}> = []

      CourseEditStatus = CourseEditStatus

      handleReviewCourse(index, row) {
          this.$router.push(`/course/edit-course/review/${row._id}`)
      }

      handleEditCourse(index, row) {
          this.$router.push(`/course/edit-course/${row._id}`)
      }

      created() {
          this.updateCourses()
          this.getInstructor()
      }

      getInstructor() {
          createRequest('/instructors', 'get').send()
              .then((res) => {
                  this.instructors = res.data.instructors.map((elem) => {
                      return toInstanceForce(new Instructor(), elem)
                  })
              })
      }

      updateCourses() {
          createRequest('/instructor/courses/reviews', 'get').send()
              .then((res) => {
                  this.tableData = res.data.reviews
              }).catch((e) => {
              console.error(e)
          })
      }

      propicURL(memberId: string) {
          return userPropicURL(memberId)
      }
  }


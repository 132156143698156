export default [
    {
        '_id': 0,
        'cname': '中文',
        'ename': 'Chinese',
        'tag': 'CHI'
    },
    {
        '_id': 1,
        'cname': '英文',
        'ename': 'English',
        'tag': 'ENG'
    },
    {
        '_id': 2,
        'cname': '數學',
        'ename': 'Mathematics',
        'tag': 'MATH'
    },
    {
        '_id': 3,
        'cname': '通識',
        'ename': 'L.S.',
        'tag': 'LS'
    },
    {
        '_id': 6,
        'cname': '中史',
        'ename': 'Chin History',
        'enameFull': 'chinese-history',
        'tag': 'CHIST'
    },
    {
        '_id': 7,
        'cname': '經濟',
        'ename': 'ECON',
        'tag': 'ECON'
    },
    {
        '_id': 9,
        'cname': '地理',
        'ename': 'GEOG',
        'enameFull': 'geography',
        'tag': 'GEOG'
    },
    {
        '_id': 10,
        'cname': '歷史',
        'ename': 'History',
        'tag': 'HIST'
    },
    {
        '_id': 12,
        'cname': '生物',
        'ename': 'BIO',
        'tag': 'BIO'
    },
    {
        '_id': 13,
        'cname': '化學',
        'ename': 'CHEM',
        'tag': 'CHEM'
    },
    {
        '_id': 14,
        'cname': '物理',
        'ename': 'PHY',
        'tag': 'PHY'
    },
    {
        '_id': 16,
        'cname': '企會財',
        'ename': 'BAFS',
        'tag': 'BAFS'
    },
    {
        '_id': 24,
        'cname': 'M1',
        'ename': 'M1',
        'tag': 'M1'
    },
    {
        "_id": 25,
        "cname": "M2",
        "ename": "M2",
        "tag": "M2"
    },
    {
        '_id': 23,
        'cname': 'VA',
        'ename': 'VA',
        'tag': 'VA'
    }
]
